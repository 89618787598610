<script lang="ts" setup>
import { useModal } from 'vue-final-modal'
import type { FieldSelectionState } from '@shared/composables/useAnnotations'

interface Props {
  state: FieldSelectionState
  rowPosition?: number
}

const props = withDefaults(defineProps<Props>(), {
  state: undefined,
  rowPosition: undefined,
})

const emits = defineEmits(['close'])

const snippet = toRef(props.state, 'snippet')
// Share screenshot modal
const { open: openModal, close } = useModal({
  component: getAsyncComponent('LeaseReviewModalShareScreenshot'),
  attrs: {
    snippet: snippet as any as string,
    id: 'modalShareScreenshotSnippet',
    clickToClose: true,
    escToClose: true,
    onClose() {
      close()
    },
  },
})
</script>

<template>
  <div
    v-if="snippet"
    class="relative col-span-full -mt-2 mr-10 overflow-hidden rounded-lg bg-gray-100 p-3 ring ring-gray-500/10 dark:bg-gray-700"
    :style="`grid-row-start: ${rowPosition}`"
  >
    <div class="relative flex justify-center">
      <div class="flex w-full max-w-[960px] self-center">
        <img :src="snippet" alt="Snippet" class="rounded-md" />
      </div>
      <div class="absolute bottom-1 right-1 flex h-7 justify-center gap-x-2">
        <!-- page number -->
        <span
          class="self-center whitespace-nowrap rounded-md bg-gray-300 px-1.5 py-1 text-[10px] font-medium text-black shadow-sm"
        >
          page: {{ state.page }}
        </span>
        <!-- share button -->
        <Button
          color="secondary"
          class="whitespace-nowrap px-3 py-0 text-center"
          icon="email-edit"
          @click="openModal"
        >
          Share
        </Button>
      </div>
    </div>

    <!-- close -->
    <Button
      type="button"
      color="secondary"
      ring
      ring-on-hover
      class="absolute right-2 top-2 inline-flex items-center justify-center rounded-lg p-2"
      icon="close"
      @click="emits('close')"
    ></Button>
  </div>
</template>
